/* theme */
body {
  font-family: 'Josefin Sans', sans-serif !important;
  background-color: #eeeeee;
  color: #505050;
}
@font-face {
  font-family: "Hermit";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/Hermit-Regular.otf");
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}
a, a:hover {
  color: #505050 !important;
}
a.about-button {
  text-decoration: none !important;
}
.btn, .btn:hover {
  background-color: #505050 !important;
}
.footer-link {
  text-decoration: none !important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}
#header-wrapper {
  margin-bottom: 30px;
}
.fa-brands {
  color: #ffffff !important;
}
.fa-brands:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.2);
}
footer {
  color: #eeeeee !important;
  background-color: #000000;
}
.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.footer-parent {
  width: 100%;
  height: 100%;
}
.footer-parent img {
  max-width: 100%;
}
.vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.bg-image {
  background-image: url("./assets/bg-01.svg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .parallax-card {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
} */
@media screen and (min-width: 1950px) {
  .font-hermit span {
    font-size: 1.5vw;
  }
}
@media screen and (min-width: 992px) {
  .parallax-card {
    max-width:60%
  }
  .text-lg-left {
    text-align: left !important;
  }
  .about-text {
    margin-left:3rem
  }
  .font-hermit span {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 769px) {
  .text-md-left {
    text-align: left !important;
  }
  #maroun {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {

  #maroun {
    font-size: 2rem;
  }
  .about-button button {
    width: 100%
  }
}

@media screen and (max-width: 991px) {
  .about-text {
    padding: 2rem 1rem;
  }
  .about-button {
    display: flex;
    justify-content: center;
  }
  .font-hermit span {
    font-size: 1.5rem;
  }
}
.logo-header {
  max-width: 500px;
  padding-right: 10%;
  padding-left:10%;
  display: flex;
  justify-content: center;
  margin: auto;
}
.hero-icons, .hero-text, #header-navbar a {
  color: #fff !important;
}
#maroun {
  color: #890000;
  font-weight: 600;
}

.font-hermit span{
  font-family: "Hermit";
}

.section-title {
  text-transform: uppercase;
  border-top: solid 4px #505050;
  border-bottom: solid 4px #505050;
  width: fit-content;
  margin: auto;
  font-weight: 700;
  font-size: 32px;
  padding: 5px
}
.section-title-white {
  text-transform: uppercase;
  border-top: solid 4px #eeeeee;
  border-bottom: solid 4px #eeeeee;
  color: #eeeeee !important;
  width: fit-content;
  margin: auto;
  font-weight: 700;
  font-size: 32px;
  padding: 5px
}
.section-subtitle {
  font-size: 14px;
  letter-spacing: 3px;
  padding: 5px;
}
.section-subtitle-white {
  color: #eeeeee !important;
  font-size: 14px;
  letter-spacing: 3px;
  padding: 5px;
}

#services-bg {
  /* background: rgba(0,0,0,.45) url('./assets/bg2.jpg');
  background-blend-mode: darken;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.services-card {
  border: solid 2px #505050;
  transition-duration: .3s;  
}
.services-card:hover {
  box-shadow: #505050 5px 5px;
}
.services-icon {
  font-size: 32px;
  border: solid 2px #505050;
  padding: 30px;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  background-color: #eee;
}
.services-title {
  font-weight: 600;
}

/* #services-bg {
  background: url('./assets/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;

} */
#portfolio-bg {
  background: rgba(0,0,0,.45) url('./assets/bg1.webp');
  background-blend-mode: darken;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.portfolio-wrap {
  background-color: #eeeeee;
  border-radius: 10px;
}
.portfolio-image {
  position: relative;
  box-shadow: #505050 10px 10px;
  border: #505050 2px solid;
}
.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
}

.portfolio-image:hover .image-overlay {
  opacity: 1;
}


.carousel-indicators [data-bs-target] {
  background-color: #555555;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}
.testimonial-comment {
  max-width: 500px;
  margin: 0 3rem;
}



.contact-section {
  background: rgba(0,0,0,.45) url('./assets/Banner.webp');
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.contact-bg {
  background-color: #000000;
  border-radius: 12px;
  margin: auto;
}

@media screen and (min-width: 940px) {
.contact-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1100px
  }
}
.email-image {
  background: url('./assets/email.webp');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  margin: auto;
  width: 200px;
}
.contact-title {
  color: #ffffff;
  font-size: 2rem;
  min-width: 300px;
  font-weight: 600;
  margin-bottom: 0;
}
.contact-title h6 {
  color: #727272;
}
.email-form {
  background-color: #e6e6e6;
  border-radius: 12px;
  min-width: 300px;
}

.button-red button {
  background-color: #890000 !important;
  border-radius: 0;
  box-shadow: #1e1e1e 5px 5px;
  border: none;
  transition: 0s background-color;
}
.button-red:hover button {
  background-color: #1e1e1e !important;
  border-radius: 0;
  box-shadow: none;
  border: none;
  transform: scale(1.05)
  translateY(2px, 2px);
  -webkit-transform: translate(2px, 2px);
  -ms-transform: translate(2px, 2px);
  transition-delay:0.05s;
  /* transition-delay:0.1s; */
}
.button-black {
  background-color: #1e1e1e !important;
  border-radius: 0;
  box-shadow: #740000 5px 5px;
  border: none;
  transition: 0s background-color;
  border-radius: 5px;
}
.button-black:hover {
  background-color: #1e1e1e !important;
  border-radius: 0;
  box-shadow: none;
  border: none;
  transform: scale(1.05)
  translateY(2px, 2px);
  -webkit-transform: translate(2px, 2px);
  -ms-transform: translate(2px, 2px);
  transition-delay:0.05s;
  border-radius: 5px;
  /* transition-delay:0.1s; */
}